<template>
    <div>
		<b-modal ref="modalEditFluxPaillettes" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("monte.stock_semence.edit_flux") }}
				</template>
			</template>

			<div class="col-12 mb-4">
				<label for="commentaire" class="col-form-label">{{ $t("monte.stock_semence.commentaire") }}</label>
				<textarea id="commentaire" class="form-control" :placeholder="$t('formulaire.votre_commentaire')" v-model="commentaire"></textarea>
			</div>

            <div class="col-12 text-center">
            	<button @click="checkForm" class="btn btn-primary rounded-pill">
        			<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                	<font-awesome-icon v-else :icon="['far', 'pencil-alt']" /> {{ $t('global.modifier') }}
                </button>
            </div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import StockSemence from '@/mixins/StockSemence.js'

export default {
	name: "ModalEditFluxPailettes",
	mixins: [StockSemence],
	data () {
		return {
			processing: false,
			commentaire: '',
			stock_id: null
		}
	},
	methods: {
		async init_component() {
			this.processing = false
		},
		openModal(params) {
			this.commentaire = params[0].commentaire
			this.stock_id = params[0].uid
			this.$refs.modalEditFluxPaillettes.show()
		},
		async checkForm() {
			this.processing = true

			await this.editCommentaireStock(this.stock_id, this.commentaire)

			this.processing = false
			this.$refs.modalEditFluxPaillettes.hide()

            this.$emit('submit')
            this.successToast("toast.info_save_succes")
		}
	}
}
</script>